.category2 {
    background-color: transparent;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.category2 h2 {
    color: white;
}

.subcategory2 {
    background-color: transparent;
    border-color: transparent;
    justify-content: space-between;
}
h4 {
    color: white;
    font-size: smaller;
    font-weight: 400;
    text-align: left;
    padding: 20px;
}


.mobilefooterbox{
    width: 100vw;
    background-color: rgb(59, 105, 166);
}

.copyright {
    height: 10vh;
    background-color: rgb(59, 105, 166);
    border-top: 0.3px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
  
.copyright h2 {
    color: white;
    font-weight: 300;
    font-size: small;
 }