.headerblock {
    z-index: 0;
    height: 10vh;
  }

/* #catalogue {
  width: 80%;
}
#catalogue button{
  background-color: rgb(59, 105, 166);
  color: white;
  margin-left: 15px;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
#catalogue button:hover{
  background-color: #0056b3;
} */

.headerblock {
  z-index: 0;
  height: 10vh;
}

#catalogue-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;
}

.catalogue {
  width: 300px;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.catalogue img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.catalogue button {
  background-color: rgb(59, 105, 166);
  color: white;
  margin-top: 10px;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.catalogue button:hover {
  background-color: #0056b3;
}

.catalogue:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
