#main {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#form {
  width: 40vw;
  height: auto;
  background-color: rgba(255, 255, 255, 0.85); /* Semi-transparent background */
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

#form form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#form h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

#form input, #form textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

#form input:focus, #form textarea:focus {
  border-color: #007BFF;
}

#form button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#form button:hover {
  background-color: #0056b3;
}
