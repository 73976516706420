.image-scroller-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-display {
    position: relative;
    width: 400px; /* Adjust the width according to your preference */
    height: auto;
    text-align: center;
  }
  
  .image-display img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .scroll-btn {
    /* position: absolute; */
    top: 50%;

    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .scroll-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .left-arrow {
    left: 10px; /* Adjust position from the left edge of the image */
  }
  
  .right-arrow {
    right: 10px; /* Adjust position from the right edge of the image */
  }
  