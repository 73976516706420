/* dengueduo.css */

#playground {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

#image {
    /* flex: 1; */
    margin-left: 200px;
    margin-top: 50px;
    max-width: 600px;
    margin-right: 40px;
    text-align: center;
}

#image img {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in ;
}
#image img:hover{
    transform: scale(1.05);
}

#info {
    /* flex: 1.5; */
    margin-right: 10%;
    max-width: 600px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
}

#info h1 {
    /* margin-left: 0; */
    font-size: 28px;
    font-weight: bolder;
    color: #333;
    margin-bottom: 0px;

}

#info h2 {
font-size: 14px;
color: #666;
line-height: 1.5;
font-weight: 100;
margin-left: 15px;
}

#info h3{
    margin-left: 20px;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
}

#info h4 {
    font-size: 18px;
    color: rgb(59, 105, 166);
    margin-top: 0px;
    margin-left: 0;
    margin-bottom: 0px;
    /* margin-left: px; */
    font-weight: bold;
}

#info button {
    background-color: rgb(59, 105, 166);
    color: white;
    margin-left: 15px;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#info button:hover {
    background-color: #0056b3;
}

/* for mobile view */
#imagemobile {

    margin-top: 10%;
}
#information{

    display: flex;
    justify-content: end;
}
#infomobile {

    margin: 10%;
    max-width: 600px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;

}

#infomobile h1 {

    font-size: 28px;
    font-weight: bolder;
    color: #333;
    margin-bottom: 0px;

}

#infomobile h2 {
font-size: 14px;
color: #666;
line-height: 1.5;
font-weight: 100;
margin-left: 15px;
}

#infomobile h3{
    margin-left: 20px;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
}

#infomobile h4 {
    font-size: 18px;
    color: rgb(59, 105, 166);
    margin-top: 0px;
    margin-left: 0;
    margin-bottom: 0px;
    /* margin-left: px; */
    font-weight: bold;
}

#infomobile button {
    background-color: rgb(59, 105, 166);
    color: white;
    margin-left: 15px;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#lowerpage{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


}
#lowerpageimg {
    /* border: 1px red solid; */
    width: 70%;


}
#lowerpageimg img{
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
ul {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 20px; /* Adds padding for bullet indentation */
    list-style-type: disc; /* Ensures a bullet (disc) list style */
}

ul li {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
}
.dropdownbutton {
    background-color: #f9f9f9;
    border-radius: 10px;
    border: none;
    padding: 15px;
    margin: 10px;
    justify-content: space-between;
}
.dropdownbutton h2{
    color: rgb(59, 105, 166);
}


/* Additional styles for the second image */
/* #playground + div img {
    margin-top: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
} */
