.footer {
    width: 100vw;
    background-color: rgb(59, 105, 166); /* Color scheme */
    display: flex; /* Flexbox layout */
    justify-content: center; /* Center content */
    padding: 20px 0; /* Padding for top and bottom */
    border-top: 1px solid #333; /* Top border */
  }
  
  
  .footerbox1, .footerbox2, .footerbox3 {
    flex: 1; /* Equal width for all boxes */
    margin: 0 10px; /* Margin between boxes */
    text-align: left;
  }
  
  .footerbox2 {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  

  

  
  /* Styles for header elements within footer boxes */
  .footerbox1 h1, .footerbox3 h1 {
    font-size: 1.5rem; 
    color: white; 
    margin-bottom: 10px; 
  }
  
  /* Styles for text elements within footer boxes */
  .footerbox1 h2, .footerbox3 h2 {
    font-weight: 400; 
    font-size: 1rem; 
    color: white; 
    margin: 5px 0; 
  }
  

  
  /* Specific styles for box1 and box3 text */
  .footerbox1 h2, .footerbox3 h2 {
    font-weight: 400; 
    font-size: 1rem; 
    color: white; 
    margin: 5px 0; 
  }
  
  /* Specific styles for box2 headers */
  .footerbox2 h1 {
    color: white;
  }

  
  /* Additional styles for a copyright section */
  .copyright {
    height: 10vh;
    background-color: rgb(59, 105, 166);
    border-top: white 0.3px solid;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  
.copyright h2 {
    color: white;
    font-weight: 300;
    font-size: small;
}
  
   
  
.copyright {
    height: 10vh;
    background-color: rgb(59, 105, 166);
    border-top: 0.3px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
  
.copyright h2 {
    color: white;
    font-weight: 300;
    font-size: small;
 }
.vl{
    border-left: white 0.3px solid;
    height: 95%;
  }


.category1 {
  background-color: transparent;
  border-color: transparent;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;


}
h3{
  color: white;
  font-size: 1rem;
  font-weight: 400;
  margin: 0px 0px 0px 8px;
}
.category1 h2{
  color: white;
  font-size: medium;
  font-weight: 600;

}






  