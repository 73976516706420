body {
  min-height: 100vh;
  background-color: rgb(242, 242, 242);
  margin: 0;
  font-family: 'Roboto', sans-serif;
  overflow-y: scroll;
}

.headerblock {
  z-index: 0;
  height: 10vh;
}

.banner{
  width: 100vw;
  height: 20vh;
 
}



.textcontainer h1 {
  font: Poppins;
  margin-left: 30px;
  margin-right: 30px;
  font-size: larger;
  color: black;
  
  
}

.textcontainer h2{
  font: Poppins;
  color: rgb(79, 79, 79);
  margin-left: 30px;
  margin-right: 30px;
  font-size: medium;
  font-weight: 400;
  margin-bottom: 30px;
}

.textcontainer {
  margin: 6vw;
  /* border: black 0.5px solid; */
  border-radius: 30px;
  border: rgba(0, 0, 0, 0) 0.5px solid;
  background: #f2f2f2;
  box-shadow:  20px 20px 60px #cecece,
               -20px -20px 60px #ffffff;

}

.outerwrap {
  display: flex;
  flex-direction: column;
  margin-left: 6vw;
  margin-right: 6vw;
  /* background-color: rgb(255, 255, 255); */
  border-radius: 30px;
  z-index: 0;
  justify-content: center;


}

.wrap {
  display: flex;
  /* margin-left: 6vw;
  margin-right: 6vw; */
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch; /* Ensures boxes are the same height */
  text-align: center;
  height: fit-content;
  border-radius: 30px;
  background: #f2f2f2;
  box-shadow:  20px 20px 60px #cecece,
               -20px -20px 60px #ffffff;

}



.wrap h1 {
  font-size: x-large;
}

.wrap h2 {
  font-weight: 400;
  font-size: medium;
  margin: 5px;
  color: rgb(79, 79, 79);
  padding: 0 10px; /* Add padding for better text spacing */
}


.box1, .box2, .box3, .box4 {
  /* border: 1px red solid; */
  flex: 1; /* Allows boxes to be evenly spaced */
  padding: 20px; /* Add padding for better text spacing */
  position: relative; /* Required for the pseudo-element to be positioned relative to this element */
}

.box1::after, .box2::after, .box3::after, .box4::after {
  content: '';
  position: absolute;
  top: 20%; /* Adjust this value to control where the border starts */
  right: 0; /* Align to the right edge */
  width: 0; /* No width since it's a border */
  height: 60%; /* Adjust this value to control the length of the border */
  border-right: 0.5px solid rgb(191, 191, 191); /* Adjust border width and color as needed */
}

.box1:last-child::after, .box2:last-child::after, .box3:last-child::after, .box4:last-child::after {
  border-right: none; /* Remove border for the last box */
}

.box1 h1, .box2 h1, .box3 h1, .box4 h1 {
  color: rgb(59, 105, 166);
}



.footerwrapper {
  display: flex; /* Flexbox layout */
  width: 90%; /* Wrapper width */
  justify-content: space-between; /* Space between boxes */
  gap: 20px; /* Gap between boxes */
}






