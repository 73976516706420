#spacing {
  height: 9vh;
}
.productsbanner {
  position: relative;
  /* display: inline-block; */
  width: 100vw; /* Set as per your image dimensions */
  height: 40vh; /* Adjust accordingly */
  box-sizing: border-box;
  overflow-y: hidden;
  /* border: 3px red solid; */
}
.bannerimage img {
  width: 100%;
  /* height: 100%; */
  filter: brightness(40%);
}

.centered_text {
  position: absolute;
  top: 50%;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Adjust text color */
  font-size: 1.5rem;
  text-align: center;
}

/* Hero */
.playground {
  margin-top: 100px;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.productlist {
  width: 40%;
  background: rgb(240, 243, 244);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center child elements */
  padding: 0; /* Remove padding if present */
}
.productlist h1 {
  color: black;
  font-weight: 600;
  font-size: xx-large;
  text-align: center;
}

.presentproduct {
  width: 60%;
  overflow-x: scroll;
}

/* dropdown css */
.itembox {
  border: 0.8px rgb(0, 0, 0) solid;
  border-radius: 8px;
  padding: 5px;
  width: 100%;
  min-width: 200px;
}
.item {
  overflow-x: scroll;
  max-width: 100%;
}
.item h1 {
  font-size: 1rem; /* Set a consistent font size */
  font-weight: 600;
  margin: 0; /* Remove default margin */
  color: #434343; /* Subtle text color */
}

.item h2 {
  font-size: 0.9rem;
  margin: 4px 10px; /* Add consistent spacing */
  color: #716f6f; /* Softer text color for descriptions */
  border-bottom: 1px solid #eee; /* Light divider between items */
  padding-bottom: 4px; /* Add spacing below text */
}

.item h2:last-child {
  border-bottom: none; /* Remove border for the last item */
}
.itembutton {
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.itembutton:hover {
  background-color: #bebebe; /* Slight hover effect */
}

/* Contact us form */
.formbox {
  border-radius: 8px;
  padding: 5px;
  width: 100%;
  max-width: 300px;
  display: flex;

  align-items: center;
  justify-content: space-around;
}
.contactform {
  min-height: 40px;
  border-radius: 8px;
  width: 100%;
}

.contactform input {
  border: 0.8px rgb(0, 0, 0) solid;
  width: 100%;
  background-color: #43434300;
  min-height: 40px;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 8px 12px;
}
.contactform input::placeholder {
  font-size: 1rem; /* Set a consistent font size */
  font-weight: 600;
  margin: 0; /* Remove default margin */
  color: #434343; 
}
.contactform button {
  background-color: rgb(59, 105, 166);
  align-self: center;
  width: 100%;
  min-width: 300px;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* product page */
