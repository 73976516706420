.coming-soon-container {
    text-align: center;

}

.coming-soon-container h1 {
    font-size: 3em;
    animation: fadeInOut 2s infinite;
}

@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}