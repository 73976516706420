.mobileheader,
.mobilescrollheader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, height 0.5s ease;
    z-index: 1000;
    /* padding: 0 15px; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobileheader {
    height: 10vh;
    background-color: rgb(59, 105, 166);
    
}

.mobilescrollheader {
    height: 5vh;
    background-color: rgba(44, 44, 44, 0.613);
}
h1 {
    color: white;
    margin: 20px;
}
.dropdown {
background-color: white;
width: 100%;
height: 100vh;
position: fixed;
z-index: 1000;
margin-left: 0;
display: flex;
justify-content: space-between;
align-items: start;
transition: background-color 0.5s ease, height 0.5s ease;
}

#menuhead{
    height: 20vh;
}

#menu,#bars{
    padding: 15px;
}

#menu h2{
    color: rgb(59, 105, 166);
    font-size: xx-large;
    font-weight: 600;
}
#rightblock{
    width: 15vw;
}
.no-scroll{
    overflow: hidden;
}








